.makeStyles-tableHeaderCell-3 {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #0068ff !important;
}

/* .table {
  min-width: none !important;
  width: '100%' !important;
}
.tablecontainer {
  border-radius: 15 !important;
  margin: '10px 10px' !important;
  max-width: '100%' !important;
}
.tableheadercell {
  font-weight: 'bold' !important;
  color: '#303f9f' !important;
}
.avatar {
  background-color: '#7986cb' !important;
  color: '#fff' !important;
}
.name {
  font-weight: 'bold' !important;
  color: '#0068ff !important' !important;
}
.status {
  font-weight: 'bold' !important;
  font-size: '0.75rem' !important;
  color: 'white' !important;
  background-color: 'grey' !important;
  border-radius: 8 !important;
  padding: '3px 10px' !important;
  display: 'inline-block' !important;
} */
