.acc-form {
  width: "100%"; /* Fix IE 11 issue.*/
  margin-top: 24px !important;
}

.submit:hover {
  background: #005ADD !important;
}

.submit {
  margin: 24px 0px 16px !important;
  background: #0068FF !important;
  padding: 6px 16px !important;
  color: #ffff !important;
  border-radius: 2px !important;
}

.msg-font-size {
  font-size: 15px;
}

.verify-email.cart-detail {
  margin: 3%;
}

.paper {
  margin-block: 100px;
  padding: 30px;
  border-radius: 10px;
  border: 2px solid #0068FF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: #0068FF;
  margin-top: 45px !important;
  font-size: 30px !important;
  font-weight: bold !important;
}

.phoneInput {
  color: #d32f2f;
  font-size: 0.75rem;
  font-weight: 500;
}

.telInput {
  width: 100%;
  height: 3.9em;
}
.verify-otp-form {
  width: "100%"; /* Fix IE 11 issue.*/
  margin-top: 24px;
}
.verify-button {
  margin: 24px 0px 16px !important;
  background: #0068FF !important;
  padding: 6px 16px !important;
  color: #ffff !important;
  border-radius: 2px !important;
}
.updateErrSpan {
  color: #f44336;
  font-size: "12px";
}

.errMsgDiv {
  background: #f2dede;
  color: #a94442;
  margin-bottom: 15px;
  padding-inline: 0;
}

.textFieldNumInput input::-webkit-clear-button, input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  display: none;
}

.msgContainer {
  text-align: center;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msgSuccess {
  background: #dff0d8;
  color: #3c763d;
  font-size: 16px;
}

.msgError {
  background: #f2dede;
  color: #a94442;
}

.msgTxtContainer {
  position: relative;
  top: 40%;
}


.auth-form-wrap{
    max-width: 560px;
    padding: 60px 0;
    margin:0 auto;
  }
  .auth-form-wrap label{
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
  }
  .auth-form-wrap .form-control{
    border-radius: 0;
    border-color: #707070;
  }
  .mobile-input select{
    width: 80px;
    margin-right: 10px;
  }
  .auth-form-wrap .form-group{
    margin-bottom: 20px;
  }
  .auth-btn-wrap button {
    cursor: pointer;
    padding: 16px 60px;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
  }
  .auth-bottom-linking{
    border-top: 2px solid #f2f2f2;
    padding-top: 30px;
    max-width: 700px;
    margin:0 auto;
  }
  .auth-bottom-content {
    text-align: center;
  }
  .auth-bottom-content a.btn-primary{
    display: inline-block;
    padding: 16px 60px;
    min-width: 400px;
    margin:10px 0 60px;
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .auth-form-wrap input {
    font-weight: bold !important;
  }
  
  .forgot-pwd{
    color: #000000;
  }
  .forgot-pwd:hover{
    color: #F7BC06;
  }
  .auth-signup-btn{
    display: block;
    color: #000;
    margin: 10px 0;
    font-weight: 700;
  }
  .auth-section p{
    text-align: center;
    margin:0px auto 30px;
    max-width: 408px;
    line-height: 23px;
  }
  .auth-section p a{
    font-weight: 700;
    color: #000;
  }
  
  .verifyEmailMsg {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    background-color: #edd381;
  }
  
  .verifyEmailMsg p {
    padding: 20px;
  }
  .auth-form-wrap input, .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-radius: 0;
    border: 1px solid #707070;
  }
  
  .auth-section p.Mui-error {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  
  .MuiFormControl-marginNormal {
    margin: 0 !important;
  }
  
  .forget-wrap {
    text-align: right;
    margin-top: 15px;
  }
  
  .resend-wrap {
    margin: 20px 0 0;
  }
  
  @media(max-width:767px){
    .auth-form-wrap, .auth-bottom-linking{
        max-width: 100%;
    }
    .auth-bottom-content a.btn-primary{
        min-width: 100%;
    }
  }
  