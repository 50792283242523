form {
  max-width: 1500px;
  margin: 0 auto;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form .form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

form .form-row label {
  margin-bottom: 0.5rem;
  color: #333333;
}

form .form-row input {
  border: 1px solid #cccccc;
  padding: 0.5em;
  font-size: 1em;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.ReactQuill {
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-bottom: 1rem;
}

button {
  margin-top: 1rem;
  padding: 0.7em 1.5em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #0056b3;
}

.error-messages {
  margin-top: 1rem;
  color: red;
}

.error-text {
  margin: 0;
  padding: 0.5rem 0;
}

.create-blog-link {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.create-blog-link:hover {
  background-color: #0056b3;
}
